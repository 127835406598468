<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('签到管理')}}</span>
        <div>
          <!-- <el-button type="primary" @click='dialogForm' size="small">新增角色</el-button> -->
          <el-button type="primary" size="small" @click="getUserList(1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <el-row :gutter="20" class="searchBar">
        <el-col :span="6">
          <el-input :placeholder="$t('请输入查询关键字')" v-model="search.keyword" clearable class="input-with-select"></el-input>
        </el-col>
        <el-col :span="8">
          <el-date-picker v-model="search.dataTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-"
            :start-placeholder="$t('开始日期')" :end-placeholder="$t('结束日期')">
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-search" @click="getUserList"></el-button>
        </el-col>
      </el-row>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column label="ID" prop="id" width="100" fixed>
        </el-table-column>
        <el-table-column :label="$t('名称')" prop="username" width="250">
        </el-table-column>
        <el-table-column :label="$t('签到地址')" prop="sign_address" width="400">
        </el-table-column>
        <el-table-column :label="$t('签到备注')" prop="sign_remark" width="500">
        </el-table-column>
        <el-table-column :label="$t('签到时间')" prop="sign_time" width="250" fixed="right">
        </el-table-column>
      </el-table>
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination> -->
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'CheckInRecord',
  data () {
    return {
      UntreatedData: [],
      search: {
        keyword: '',
        dataTime: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      }
    }
  },
  beforeMount () {
    this.getUserList()
  },
  methods: {
    // 获取列表
    getUserList (is_report) {
      var start, end, params
      // 时间搜索值为空时自动填补最近一个月
      if (this.search.dataTime) {
        start = this.search.dataTime[0]
        end = this.search.dataTime[1]
      } else {
        var time1 = new Date()
        time1.setTime(time1.getTime() - 3600 * 1000 * 24 * 31)
        start = this.$common.formatTime(time1)
        end = this.$common.formatTime(new Date())
        this.search.dataTime = [start, end]
      }
      //
      params = {
        keyword: this.keyword,
        start_time: start,
        end_time: end
      }
      if (is_report) {
        params.is_report = is_report
        this.$common.postExcelFile(params, 'work_order/sign_list')
        return
      }
      this.$axios.post('work_order/sign_list', params).then((res) => {
        // console.log(res)
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
        } else this.$message.error(Data.message)
      }).catch((error) => console.log(error))
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  .searchBar {
    margin: 20px 0;
  }
  .el-range-editor.el-input__inner{
    width: 100%;
  }
</style>
